<template>
  <main
    role="main"
    :style="[
      branding
        ? {
            'background-color': `#${branding.backgroundColor}`,
            color: `#${branding.textColor}!important`
          }
        : { 'background-color': '#e8e8e8', color: '#000000' }
    ]"
  >
    <meta-title :title="metaTitle"></meta-title>
    <not-active-header></not-active-header>
    <section
      v-if="branding"
      class="section branding-section"
      :style="{ 'background-color': `#${branding.backgroundColor}` }"
    >
      <picture>
        <img
          :src="branding.headerBanner.image.replace('{sizeId}', getSizeImage)"
          alt=""
        />
      </picture>
    </section>
    <section
      class="section section-localnav"
      v-if="indexSectionLoading === false"
      :style="[
        branding
          ? {
              'background-color': `#${branding.backgroundColor}`
            }
          : { 'background-color': '#e8e8e8' }
      ]"
    >
      <nav class="tabnav">
        <ul class="tabnav-items">
          <li class="tabnav-offset-item"><div></div></li>
          <li
            class="tabnav-item"
            :class="{
              active: +sectionItem.id === +collection,
              'tabnav-first-item': sectionIndex === 0,
              'tabnav-last-item':
                getSection(section).length - 1 === sectionIndex
            }"
            v-for="(sectionItem, sectionIndex) in getSection(section)"
            :key="sectionItem.id"
          >
            <em></em>
            <router-link
              :to="{
                name: 'MovieList',
                params: {
                  section: section,
                  collection: sectionItem.id
                }
              }"
              class="tabnav-link"
              >{{ sectionItem.title }}</router-link
            >
          </li>

          <li class="tabnav-offset-item"><div></div></li>
        </ul>
      </nav>
    </section>
    <transition name="fade">
      <section
        :style="{ 'background-color': `#${branding.backgroundColor}` }"
        class="section section-tiles"
        v-if="indexCollectionLoading === false"
      >
        <div class="container m-container-only-s">
          <ul>
            <li
              :class="{
                'full-width': collectionItem.isFeatured,
                drm: collectionItem.isDrm,
                album:
                  indexCollectionData.contentType === 'Album' ||
                  indexCollectionData.contentType === 'Book'
              }"
              v-for="collectionItem in indexCollectionData.contents"
              :key="collectionItem.id"
            >
              <i
                v-if="
                  collectionItem.isPaid && !checkPurchased(collectionItem.id)
                "
              ></i>
              <router-link :to="{ path: generateRouteUrl(collectionItem) }">
                <lazy-load
                  :id="collectionItem.id"
                  :image-source="replaceCoverImage(collectionItem.cover)"
                  :loading-image="
                    replacePreloadCoverImage(collectionItem.cover)
                  "
                  :error-image="replacePreloadCoverImage(collectionItem.cover)"
                  :drm="collectionItem.isDrm"
                  :drm-active="enabledDrm"
                ></lazy-load>
                <p>
                  {{ collectionItem.title }}
                  <span
                    v-if="
                      indexCollectionData.contentType !== 'Album' &&
                        indexCollectionData.contentType !== 'Book' &&
                        indexCollectionData.contentType !== 'Microsite'
                    "
                    style="opacity: 0.5"
                    :style="[
                      branding
                        ? {
                            color: `#${branding.textColor}!important`
                          }
                        : { color: '#000000' }
                    ]"
                    >• {{ collectionItem.year }}</span
                  >
                </p>
              </router-link>
            </li>
          </ul>
        </div>
      </section>
    </transition>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import cover from "@/mixins/cover";
import LazyLoad from "@/components/plugins/lazy-load";
import NotActiveHeader from "@/components/drm/NotActiveHeader";
import events from "@/mixins/events";

export default {
  name: "MovieList",
  components: { "lazy-load": LazyLoad, "not-active-header": NotActiveHeader },
  title() {
    return `${this.indexCollectionData.title}`;
  },
  props: ["section", "collection"],
  mixins: [cover, events],
  computed: {
    ...mapState({
      width: state => state.width,
      height: state => state.height
    }),
    getSizeImage() {
      if (this.width > 1439) {
        return 7;
      } else if (this.width > 1279) {
        return 6;
      } else if (this.width > 1023) {
        return 5;
      } else if (this.width > 767) {
        return 5;
      } else if (this.width > 413) {
        return 3;
      } else if (this.width > 374) {
        return 2;
      } else {
        return 1;
      }
    },
    ...mapState("order", {
      purchasedContent: state => state.purchasedContent
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    ...mapGetters("section", { getSection: "getSectionCollection" }),
    ...mapState("collection", {
      indexCollectionData: state => state.index,
      indexCollectionLoading: state => state.indexLoading
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm,
      brandings: state => state.brandings
    }),
    ...mapState({
      locale: state => state.locale
    }),
    branding() {
      if (this.indexCollectionData.brandingId) {
        return this.brandings.find(
          item => item.id === this.indexCollectionData.brandingId
        );
      }

      return false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.actionCollectionIndex(to.params.collection);
    console.log("update");
    this._collectionEvent(to.params.collection);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionCollectionIndex(to.params.collection);

      vm._collectionEvent(to.params.collection);
    });
  },
  watch: {
    indexSectionLoading: {
      handler() {
        if (this.indexSectionLoading === "empty") {
          this.actionSectionIndex();
        }
      },
      immediate: true
    },
    indexCollectionLoading: {
      handler() {
        if (this.indexCollectionLoading === "empty") {
          this.actionCollectionIndex(this.collection);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("section", {
      actionSectionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapActions("collection", {
      actionCollectionIndex: "index"
    }),
    ...mapMutations("collection", {
      setEntityCollection: "SET_ENTITY"
    }),
    ...mapMutations("section", {
      setEntitySection: "SET_ENTITY"
    }),
    checkPurchased(contentId) {
      return (
        (this.purchasedContent &&
          this.purchasedContent.length &&
          !!this.purchasedContent
            .filter(
              item =>
                item.payloadContent &&
                item.state === "completed" &&
                item.payloadContent.content.paymentType !== "collection"
            )
            .find(
              item =>
                item.payloadContent &&
                item.payloadContent.content.ids.find(it => it === +contentId)
            )) ||
        (this.purchasedContent &&
          this.purchasedContent.length &&
          !!this.purchasedContent
            .filter(
              item =>
                item.payloadContent &&
                item.state === "completed" &&
                item.payloadContent.content.paymentType === "collection"
            )
            .find(
              item =>
                item.payloadContent &&
                item.payloadContent.content.ids.find(it => it === +this.section)
            ))
      );
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
