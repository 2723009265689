<template>
  <section class="section section-info" v-if="!enabledDrm && !skipHeaderDrm">
    <div class="container m-container-only-s">
      <p>
        {{ $t("settings.device_not_active") }}
      </p>
      <a
        href=""
        @click.prevent="setSkipHeaderDrm(['skipHeaderDrm', true])"
        class="close"
      ></a>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "NotActiveHeader",
  computed: {
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm,
      skipHeaderDrm: state => state.skipHeaderDrm
    })
  },
  methods: {
    ...mapMutations("settings", {
      setSkipHeaderDrm: "SET_ENTITY"
    })
  }
};
</script>

<style scoped></style>
