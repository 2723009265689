var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{style:([
    _vm.branding
      ? {
          'background-color': ("#" + (_vm.branding.backgroundColor)),
          color: ("#" + (_vm.branding.textColor) + "!important")
        }
      : { 'background-color': '#e8e8e8', color: '#000000' }
  ]),attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('not-active-header'),(_vm.branding)?_c('section',{staticClass:"section branding-section",style:({ 'background-color': ("#" + (_vm.branding.backgroundColor)) })},[_c('picture',[_c('img',{attrs:{"src":_vm.branding.headerBanner.image.replace('{sizeId}', _vm.getSizeImage),"alt":""}})])]):_vm._e(),(_vm.indexSectionLoading === false)?_c('section',{staticClass:"section section-localnav",style:([
      _vm.branding
        ? {
            'background-color': ("#" + (_vm.branding.backgroundColor))
          }
        : { 'background-color': '#e8e8e8' }
    ])},[_c('nav',{staticClass:"tabnav"},[_c('ul',{staticClass:"tabnav-items"},[_vm._m(0),_vm._l((_vm.getSection(_vm.section)),function(sectionItem,sectionIndex){return _c('li',{key:sectionItem.id,staticClass:"tabnav-item",class:{
            active: +sectionItem.id === +_vm.collection,
            'tabnav-first-item': sectionIndex === 0,
            'tabnav-last-item':
              _vm.getSection(_vm.section).length - 1 === sectionIndex
          }},[_c('em'),_c('router-link',{staticClass:"tabnav-link",attrs:{"to":{
              name: 'MovieList',
              params: {
                section: _vm.section,
                collection: sectionItem.id
              }
            }}},[_vm._v(_vm._s(sectionItem.title))])],1)}),_vm._m(1)],2)])]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.indexCollectionLoading === false)?_c('section',{staticClass:"section section-tiles",style:({ 'background-color': ("#" + (_vm.branding.backgroundColor)) })},[_c('div',{staticClass:"container m-container-only-s"},[_c('ul',_vm._l((_vm.indexCollectionData.contents),function(collectionItem){return _c('li',{key:collectionItem.id,class:{
              'full-width': collectionItem.isFeatured,
              drm: collectionItem.isDrm,
              album:
                _vm.indexCollectionData.contentType === 'Album' ||
                _vm.indexCollectionData.contentType === 'Book'
            }},[(
                collectionItem.isPaid && !_vm.checkPurchased(collectionItem.id)
              )?_c('i'):_vm._e(),_c('router-link',{attrs:{"to":{ path: _vm.generateRouteUrl(collectionItem) }}},[_c('lazy-load',{attrs:{"id":collectionItem.id,"image-source":_vm.replaceCoverImage(collectionItem.cover),"loading-image":_vm.replacePreloadCoverImage(collectionItem.cover),"error-image":_vm.replacePreloadCoverImage(collectionItem.cover),"drm":collectionItem.isDrm,"drm-active":_vm.enabledDrm}}),_c('p',[_vm._v(" "+_vm._s(collectionItem.title)+" "),(
                    _vm.indexCollectionData.contentType !== 'Album' &&
                      _vm.indexCollectionData.contentType !== 'Book' &&
                      _vm.indexCollectionData.contentType !== 'Microsite'
                  )?_c('span',{staticStyle:{"opacity":"0.5"},style:([
                    _vm.branding
                      ? {
                          color: ("#" + (_vm.branding.textColor) + "!important")
                        }
                      : { color: '#000000' }
                  ])},[_vm._v("• "+_vm._s(collectionItem.year))]):_vm._e()])],1)],1)}),0)])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])}]

export { render, staticRenderFns }