<template>
  <figure :class="[imageState]" :style="computedStyle" :data-state="imageState">
    <figcaption v-if="drm && !drmActive">
      <p>
        {{ $t("settings.not_active_movie") }}
        <router-link to="/help" class="info"></router-link>
      </p>
    </figcaption>
  </figure>
</template>

<script>
export default {
  props: {
    imageSource: {
      type: String,
      required: true
    },
    loadingImage: {
      type: String,
      required: true
    },
    errorImage: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    drm: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    drmActive: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      imageState: "loading",
      asyncImage: new Image()
    };
  },
  computed: {
    computedStyle() {
      if (this.imageState === "loading") {
        return "background-image: url(" + this.loadingImage + ");";
      }
      if (this.imageState === "error") {
        return "background-image: url(" + this.errorImage + ");";
      }
      if (this.imageState === "loaded") {
        return "background-image: url(" + this.asyncImage.src + ");";
      }
      return "";
    }
  },
  methods: {
    fetchImage() {
      this.asyncImage.onload = this.imageOnLoad;
      this.asyncImage.onerror = this.imageOnError;
      this.imageState = "loading";
      this.asyncImage.src = this.imageSource;
    },
    imageOnLoad() {
      this.imageState = "loaded";
      this.imageWidth = this.asyncImage.naturalWidth;
      this.imageHeight = this.asyncImage.naturalHeight;
    },
    imageOnError() {
      this.imageState = "error";
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchImage();
    });
  }
};
</script>
